.footer {
  padding: 0.5rem 1.5rem;
  color: var(--color-secondary-main);
}

.footer.login {
  position: fixed;
  text-align: end;
  bottom: 0.5rem;
  right: 0.5rem;
}

.footer.main {
  text-align: center;
}
