@font-face {
  font-family: 'Manrope Regular';
  src: url('../assets/fonts/manrope/fonts/Manrope-Regular.ttf');
}

@font-face {
  font-family: 'Manrope Medium';
  src: url('../assets/fonts/manrope/fonts/Manrope-Medium.ttf');
}

@font-face {
  font-family: 'Manrope Bold';
  src: url('../assets/fonts/manrope/fonts/Manrope-Bold.ttf');
}
