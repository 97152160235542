body {
  margin: 0;
  font-family: 'Manrope Medium', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-s);
  width: 100%;
  min-height: 100vh;
  background: var(--color-bg);
  overflow-x: auto;
  overflow-y: auto;
}

#root {
  width: 100%;
  height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

img {
  display: block;
  width: 100%;
  height: auto;
}
