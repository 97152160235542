:root {
  /* colors */
  --color-white: #fff;
  --color-bg: #f8f8f8;

  --color-primary-main: #212121;
  --color-primary-grey: #616161;

  --color-secondary-main: #d32f2f;

  /* Fonts */
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 18px;
  --font-size-xl: 24px;
  --font-size-xxl: 30px;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --main-font-family-regular: 'Manrope Regular', sans-serif;
  --main-font-family-medium: 'Manrope Medium', sans-serif;
  --main-font-family-bold: 'Manrope Bold', sans-serif;

  /* Media */
  --xs: 0px;
  --sm: 990px;
  --md: 1240px;
  --lg: 1520px;
  --xl: 1920px;
}
